import React, { forwardRef, useCallback } from 'react';
import DatePickerLib from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDate } from 'utils';
import { minStartDate } from 'constants/index';

const CustomInput = forwardRef(
  ({ rawValue, onClick, className }: any, ref) => {
    const customDate = rawValue ? getDate(rawValue) : null;

    if (!customDate) return null;

    return (
      // @ts-ignore
      <button className={className} onClick={onClick} ref={ref}>
        {`${customDate.mon} ${customDate.d}, ${customDate.y}`}
      </button>);
  },
);


interface Props {
  date: Date,
  handleChange: (e: any) => void
}

const DatePicker = ({date, handleChange}: Props) => {
    const handleDateChange = useCallback((date: Date | null) => {

    if (date === null) return;

    handleChange(date);
  }, []);
  return (
    <DatePickerLib
      selected={date}
      onChange={handleDateChange}
      minDate={minStartDate}
      maxDate={new Date()}
      customInput={<CustomInput className="chart-date" rawValue={date} />}
    />
  );
};

export default DatePicker;