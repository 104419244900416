import React, { useMemo } from 'react';
import charts from 'config/charts';
import IframeChart from 'components/IframeChart';

const Main = () => {
  const chartsArr = useMemo(() => {
    return Object.keys(charts).map(name => charts[name]);
  }, []);

  return (
    <div className="main-wrapper">
      <main className="main">
        {chartsArr.map(chart => (
          <div className="main__item" key={chart.src}>
            <div className="main__item_inner">
              <IframeChart chart={chart} />
            </div>
          </div>
        ))}
      </main>
    </div>
  );
};

export default Main;
