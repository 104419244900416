import React from 'react';
import './App.scss';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';
import Layout from './components/Layout';
import Main from './components/Main';

function App() {
  return (
    <Authenticator hideSignUp>
      {({ signOut, user }) => (
        <Layout>
          <Main />
        </Layout>
      )}
    </Authenticator>
  );
}

export default App;
