const config = {
  Auth: {
    Cognito: {
      // TODO:env var config: use env variables when ci-cd proccess is configured
      // userPoolClientId: process.env.REACT_APP_AWS_USERPOOL_WEB_CLIENT_ID || '',
      // userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID || '',
      userPoolClientId: '5a28fqvj08qic8nbmhrjmrnd2p',
      userPoolId: 'us-west-1_O0a0YEgDY',
       loginWith: {
         oauth: {
           domain: 'garnet.auth.us-west-1.amazoncognito.com',
           scopes: ['openid','email','phone','profile'],
           redirectSignIn: ['https://garnet.rd.klika-tech.com'],
           redirectSignOut: ['https://garnet.rd.klika-tech.com'],
           responseType: 'code',
         }
      //   username: 'true',
      //   email: 'false', // Optional
      //   phone: 'false', // Optional
       }
    }
  }
};

export default config;
