import { minStartDate } from 'constants/index';
import { Chart } from 'types/Chart';
import ValveStatus from 'components/ValveStatus';

const charts: Record<string, Chart> = {
  chart1: {
    title: 'Daily Total Water Usage',
    subtitle: 'Month',
    units: 'gallons',
    src: '/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-20d&to=now&refresh=5m&theme=light&panelId=7',
    timeRange: 20*24*60*60*1000,
    minStartDate: minStartDate,
  },
  chart2: {
    title: 'Average Hourly Water Usage',
    subtitle: 'Day',
    units: 'gallons',
    src: '/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-48h&to=now-24h&refresh=5m&theme=light&panelId=4',
    timeRange: 24*60*60*1000,
    timeTo: {h: 23, m: 59, s: 59},
    minStartDate: minStartDate,
  },
  chart3: {
    title: 'Cumulative usage',
    subtitle: 'Day',
    units: 'gallons',
    src: '/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-32h&to=now-25h&refresh=5m&theme=light&panelId=6',
    timeRange: 24*60*60*1000,
    timeTo: {h: 23, m: 59, s: 59},
    minStartDate: minStartDate,
  },
  chart4: {
    title: 'Valve Status Over Time',
    src: '/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-12h&to=now&refresh=5m&theme=light&panelId=2',
    timeRange: 12*60*60*1000,
    minStartDate: minStartDate,
    addon: {
      componentGetter: props => <ValveStatus {...props} />,
      // TODO consider to move here necessary settings for ValveStatus component, fetch params.
      // Needed additional investigation how to simplify a request body
      props: {/*refresh: 30000*/},
    },
  },
};

export default charts;
