import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Chart } from 'types/Chart'
import DatePicker from 'components/Datepicker';

interface Props {
  chart: Chart,
}

const IframeChart = ({ chart }: Props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [src, setSrc] = useState(chart.src);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleDateChange = useCallback((date: Date) => {
    const currentDate = new Date();
    const newDate = new Date(date);

    if (!chart.timeTo) {
      newDate.setHours(currentDate.getHours());
      newDate.setMinutes(currentDate.getMinutes());
    } else {
      newDate.setHours(chart.timeTo.h);
      newDate.setMinutes(chart.timeTo.m);
      newDate.setSeconds(chart.timeTo.s);
    }

    const newSrc = src
      .replace(/(from=)[^&]+/, `$1${newDate.valueOf() - chart.timeRange}`)
      .replace(/(to=)[^&]+/, `$1${newDate.valueOf()}`);

    setSrc(newSrc);
    setIsLoaded(false);
    setStartDate(newDate);
  }, [chart, src]);

  const handleIframeLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const Addon: ReactElement | null = useMemo(() => {
    const componentGetter = chart.addon?.componentGetter;
    const addonProps = chart.addon?.props || {};

    if (!componentGetter) return null;

    return componentGetter({...addonProps, date: startDate, chart, isMasterLoaded: isLoaded});
  }, [chart, startDate, isLoaded]);

  return (
    <div className="iframe-container">
      <div className="iframe-container__header">
        <h2 className="iframe-container__title">{chart.title}</h2>
        <div>
          <DatePicker handleChange={handleDateChange} date={startDate}/>
        </div>
      </div>
      <div className="iframe-container__content iframe-wrapper">
        <div className="iframe-wrapper__inner">
          {Addon ?  (
            <div>
              {Addon}
            </div>
          ) : null}

          <div className="edge-cut">
            <iframe
              src={src}
              width="450"
              height="200"
              frameBorder="0"
              title={chart.title}
              onLoad={handleIframeLoad}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IframeChart;
